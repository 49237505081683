<template>
    <!-- <div> -->
        <div ref="optionsContainer" class="o365-select-options-definitions d-none">
            <slot></slot>
        </div>
        <ODropdown ref="dropdownRef" @open="onOpen">
                <template #default="{target, open }">
                <slot name="target" :target="target" :open="open">
                    <div class="position-relative" :class="wrapperClass" :style="wrapperStyle">
                        <input :ref="target" :value="displayValue" class="o365-select text-truncate lookup-icon form-control" :class="inputClass" readonly @click="open" @blur="preventBlur"
                            @focus="open" v-bind="$attrs">
                    </div>
                </slot>
            </template>
            <template #dropdown="{container, close}">
                <div :ref="container" class="shadow dropdown-menu o365-select-container card" :class="containerClass" :style="{'width': dropdownWidth+'px'}">
                    <input type="text" class="form-control mx-2 display-flex w-auto" :placeholder="$t('Filter...')" v-model="filterTerm">
                    <div class="o365-select-container mt-2">
                        <button
                            v-for="option in selectOptions"
                            @click="() => selectValue(option.value, close)"
                            @touchend.prevent="() => selectValue(option.value, close)"
                            class="dropdown-item py-0"
                            :style="{'height': itemSize + 'px'}"
                            :class="[option.class, {'active': option.active}, itemClass]"
                        >
                            {{option.display ?? option.value}}
                        </button>
                    </div>
                </div>
            </template>
        </ODropdown>
    <!-- </div> -->
</template>

<script setup lang="ts">
import { $t } from "o365-utils";
import { computed, ref, reactive, defineComponent, onMounted, onUpdated } from 'vue';
import { ODropdown } from 'o365-ui-components'

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void
    (e: 'selected', value: any): void
}>();

const props = withDefaults(defineProps<{
    modelValue: any,
    itemSize?: number | string,
    minWidth?: number | string,
    widthPadding?: number | string,
    inputClass?: any,
    wrapperClass?: any,
    wrapperStyle?: any,
    containerClass?: any,
    itemClass?: any,
    inputType?: string
}>(), {
    itemSize: 24,
    inputType: 'text'
});

const optionsContainer = ref<HTMLElement|null>(null);
const dropdownRef = ref<InstanceType<typeof ODropdown>|null>(null);
const dropdownWidth = ref(200);
const options = ref<{
    value: any,
    display: any,
    class: string,
}[]>([]);
const filterTerm = ref('');

const displayValue = computed(() => {
    const selectedOption = options.value.find(x => x.value == props.modelValue);
    if (selectedOption) {
        return selectedOption.display;
    } else {
        return props.modelValue;
    }
});

const selectOptions = computed(() => {
    let activeFound = false;
    let filtered = options.value;

    if (filterTerm.value) {
        filtered = options.value.filter((option) =>
            option.display.toLowerCase().includes(filterTerm.value.toLowerCase())
        );
    }

    return filtered.map(option => {
        let isActive = false;
        isActive = !activeFound && option.value === props.modelValue;
        if (isActive) { activeFound = true; }

        return {
            value: option.value,
            display: option.display,
            class: option.class,
            active: isActive
        };
    });
});

onMounted(() => {
    getOptionsFromDOM();
});

onUpdated(() => {
    getOptionsFromDOM();
})

function selectValue(pValue: any, pClose?: ()=> void) {
    emit('update:modelValue', pValue);
    emit('selected', pValue);
    if (pClose) {
        pClose();
    }
}

function preventBlur(pEvent) {
    try {
        // this check doesn't work on safari. pEvent.relatedTarget = null somehow
        if(dropdownRef.value?.container?.contains(pEvent.relatedTarget)) {
            pEvent.stopPropagation();
        }
    } catch (ex) {
        console.error(ex);
    }
    closeDropdown(pEvent);
}

function closeDropdown(event: FocusEvent) {
    if ((event.relatedTarget as HTMLElement)?.closest('.o365-select-container')) { // <-- this doesn't work on safari. relatedTarget is null 
        return;
    }
    if (dropdownRef.value?.close) {
        dropdownRef.value.close();
    }
}

function getOptionsFromDOM() {
    options.value = [];
    if (optionsContainer.value) {
        optionsContainer.value.querySelectorAll('option').forEach(option => {
            let value: null | undefined | string = undefined;
            if ((option as any)._value != null) {
                value = (option as any)._value
            } else if (option.value === null) {
                value = null;
            } else if (option.value != null && option.dataset.undefined == null) {
                value = option.value;
            } else if (option.dataset.undefined == null) {
                value = option.innerText;
            }
            options.value.push({
                value: value,
                display: option.innerText,
                class: option.className,
            });
        });
    }
}

function onOpen() {
    filterTerm.value = '';
}

</script>

<style scoped>
.o365-select-container {
    max-height: 450px;
    overflow-y: auto;
}

.o365-select-container .focused:not(.active) {
    background-color: rgba(var(--bs-primary-rgb), .25);
}
.o365-select-container .active:not(.focused) {
    background-color: rgba(var(--bs-primary-rgb), .75);
}
.o365-select-container .focused.active {
    background-color: rgba(var(--bs-primary-rgb), 1);
}

.o365-select{
    padding-right: 3.33rem!important;
}
</style>